.detail-index-top-right {
  .right-top{
    min-height: 3.2rem;
  }
  .culture-detail-top-title {
    font-size: (22rem / 100);
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    line-height: (34rem / 100);
    color: #0f0f0f;
    margin-bottom: (15rem / 100);
    width: (496rem / 100);
    // height: (64rem / 100);
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 2;
    // overflow: hidden;
  }

  .culture-detail-top-des {
    // margin-bottom: (92rem / 100);

    &-ul {
      width: 100%;
      font-size: (16rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #273143;
      line-height: (22rem / 100);
      color: #232323;

      &-li {
        height: (24rem / 100);
        margin-bottom: (10rem / 100);
        display: flex;
        align-items: center;
      }
    }
  }

  .culture-detail-top-button {
    width: (120rem / 100);
    height: (40rem / 100);
    background: #761f1e;
    border-radius: (50rem / 100);
    text-align: center;
    line-height: (40rem / 100);
    font-size: (16rem / 100);
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    color: #ffffff;
  }
  .gray{
    background: #8797AD;
  }
  .started {
    background-color: rgb(207, 181, 140);
    cursor: auto;
  }
  .finished {
    background-color: #ccc;
    cursor: auto;
  }
}

.detail-index-bottom-content {
  padding: 0 (16rem / 100) (146rem / 100) 0;

  &-top {
    width: (1200rem / 100);
    height: (100rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_bottom_top_bg.png")
      no-repeat;
    background-size: 100% 100%;
    box-shadow: 0 5px 15px 0 rgba(207, 181, 140, 0.8);
    display: flex;
    align-items: center;
    padding-left: (30rem / 100);
    margin-bottom: (28rem / 100);

    &-title {
      width: (140rem / 100);
      height: (31rem / 100);
      text-align: center;
      line-height: (31rem / 100);
      font-size: (20rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
      background: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_title_bg.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .detail-index-bottom-content-bottom-box {
    margin-bottom: (44rem / 100);
    padding: 0 (30rem / 100);

    &-top {
      display: flex;
      align-items: center;
      font-size: (16rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #273143;
      line-height: (16rem / 100);
      margin-bottom: (15rem / 100);

      &-icon {
        width: (9rem / 100);
        height: (16rem / 100);
        margin-right: (7rem / 100);
      }
    }

    &-bottom {
      padding-left: (16rem / 100);
      font-size: (14rem / 100);
      font-family: PingFangSC-Regular, PingFang SC;
      color: #273143;
      line-height: (28rem / 100);
    }
  }
}
.culture-detail-ul {
  padding-left: 0.2rem;
  .culture-detail-ul-li {
    .culture-detail-ul-li-title {
      color: #333B47;
      line-height: 0.18rem;
      font-size: 0.18rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      margin-bottom: 0.14rem;
    }
    .culture-detail-ul-li-item2{
      display: flex;
      // padding-left: 0.25rem;
      align-items: center;
      margin-bottom: 0.1rem;
      .culture-detail-ul-li-icon2{
        width: 0.24rem;
        height: 0.24rem;
        margin-right: 0.1rem;
      }
      .culture-detail-ul-li-title2{
        color: #6F767D;
        line-height: 0.22rem;
        font-size: 0.16rem;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }
    .culture-detail-ol {
      .culture-detail-ol-li {
        .culture-detail-ol-li-item {
          display: flex;
          align-items: center;
          margin-bottom: 0.05rem;
          .culture-detail-ol-li-icon {
            width: 0.08rem;
            height: 0.08rem;
            background-color: rgba($color: #9fafe5, $alpha: 0.73);
            border-radius: 0.25rem;
            margin-right: 0.15rem;
            // padding-left: 0.02rem;
          }
          .culture-detail-ol-li-title {
            color: #2D2D2D;
            line-height: 0.31rem;
            font-size: 0.16rem;
            font-family: PingFangSC-Regular, PingFang SC;
            
          }
        }
        .culture-detail-ol-li-item2{
          display: flex;
          padding-left: 0.25rem;
          align-items: center;
          margin-bottom: 0.1rem;
          .culture-detail-ol-li-icon2{
            width: 0.24rem;
            height: 0.24rem;
            margin-right: 0.1rem;
          }
          .culture-detail-ol-li-title2{
            color: #6F767D;
            line-height: 0.22rem;
            font-size: 0.16rem;
            font-family: PingFangSC-Regular, PingFang SC;
          }
        }
        
        .culture-detail-ol2 {
          padding-left: 0.15rem;
          .culture-detail-ol2-li {
            .culture-detail-ol2-li-item {
              display: flex;
              align-items: center;
              color: #2D2D2D;
              font-size: 0.16rem;
              font-family: PingFangSC-Regular, PingFang SC;
              line-height: 0.31rem;
              margin-bottom: 0.05rem;
              .culture-detail-ol2-li-icon {
              }
              .culture-detail-ol2-li-title {
              }
            }
            .culture-detail-ol2-li-item2{
              display: flex;
              align-items: center;
              padding-left: 0.1rem;
              margin-bottom: 0.1rem;
              .culture-detail-ol2-li-icon2{
                width: 0.24rem;
                height: 0.24rem;
                margin-right: 0.1rem;
              }
              .culture-detail-ol2-li-title2{
                color: #6F767D;
                line-height: 0.22rem;
                font-size: 0.16rem;
                font-family: PingFangSC-Regular, PingFang SC;
              }
            }
          }
        }
      }
    }
  }
}
