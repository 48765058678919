.crumbs-container{
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
    .crumbs-home{
        color: #273143;
        font-size: 0.16rem;
        font-family: PingFangSC-Medium, PingFang SC;
        line-height: 1.5;
        margin-right: 0.1rem;
        cursor: pointer;
    }
    // .crumbs-home:hover{
    //     color: #8F9ED3;
    // }
    .crumbs-nav{
        display: flex;
        align-items: center;
        .crumbs-nav-text{
            color: #273143;
            font-size: 0.16rem;
            font-family: PingFangSC-Medium, PingFang SC;
            line-height: 1.5;
            margin-right: 0.1rem;
            cursor: pointer;
        }
        // .crumbs-nav-text:hover{
        //     color: #8F9ED3;
        // }
        
        .crumbs-nav-slash{
            color: #273143;
            font-size: 0.16rem;
            font-family: PingFangSC-Medium, PingFang SC;
            line-height: 1.5;
            margin-right: 0.1rem;
        }
    }
    .crumbs-nav:last-of-type{
        .crumbs-nav-text{
            color: #761f1e;
        }
        
    }
    .crumbs-xq{
        color: #6D80C5;
        font-size: 0.16rem;
            font-family: PingFangSC-Medium, PingFang SC;
            line-height: 1.5;
    }
}