
.dialog-container {
  width: 7rem;
  height: 5rem;
  background: url("https://image.bookgo.com.cn/webculture/jm/dialog/dialog-bg.png")
    no-repeat;
  background-size: 100% 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  text-align: center;
  padding: 0.3rem;
  &-QRCode {
    width: 2.7rem;
    height: 2.71rem;
    background: url("https://image.bookgo.com.cn/webculture/jm/dialog/dialog-QRCode-bg.png")
      no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 0.3rem;
    padding: 0.25rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h3 {
    font-size: (28rem/100);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273143;
    line-height: 0.33rem;
    margin-top: 0.1rem;
    margin-bottom: 0.4rem;
  }
  .closeBtn {
    position: absolute;
    width: 0.4rem;
    height: 0.4rem;
    top: 0.3rem;
    right: 0.3rem;
    cursor: pointer;
  }
  p {
    font-size: (20rem/100);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273143;
    line-height: 0.16rem;
  }
  &-mask {
    width: 30rem;
    height: 30rem;
    background-color: rgba($color: #273143, $alpha: 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7;
  }
}
